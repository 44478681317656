import RmDelayWarning from 'components/RmDelayWarning'
import { Link } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const RegistrationConfirmation = () => {
  return (
    <>
      <GatsbySeo title="Registration Confirmation" />

      <section className="section">
        <div className="container is-max-desktop">
          <h2 className="title has-text-midnightBlue is-spaced is-size-4-mobile">Thanks for registering!</h2>

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Important - you must do the following</h3>

          <div className="content">
            <ul>
              <li>Write your details on the request form</li>
              <li>Write your name and date of birth on sample tube</li>
              <li>
                Include the request form <em>with</em> your sample when returning it to the lab
              </li>
            </ul>
          </div>

          <RmDelayWarning />

          <h3 className="subtitle has-text-midnightBlue is-size-6-mobile">Need any tips on taking your sample?</h3>

          <div className="content">
            <p>
              This video has some useful tips for taking a nose and throat swab to test for coronavirus. Please note
              that the packaging and labelling are different for your kit but the actual sampling process is the same:
            </p>
          </div>

          <div className="video-container">
            <iframe
              title="Instruction video (Youtube)"
              src="https://www.youtube-nocookie.com/embed/8lo6g-TYZ-c"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <hr />

          <p className="mt-3">
            <Link to="/register">Register another kit</Link>
          </p>
        </div>
      </section>
    </>
  )
}

export default RegistrationConfirmation
