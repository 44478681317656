const RmDelayWarning = () => (
  <div className="px-3 py-2 has-background-warning-light content">
    <p>
      Royal Mail is experiencing higher than normal postal delays and we have seen an increase in the number of samples
      taking 48 hours or more to be delivered.
    </p>

    <p>
      We do not recommend using Royal Mail for time-sensitive sample returns at this time. We strongly recommend that
      you hand-deliver or courier your samples to our lab partner where possible.
    </p>

    <p>
      For more information please see{' '}
      <a href="https://personal.help.royalmail.com/app/answers/detail/a_id/12556" rel="noopener noreferrer">
        Royal Mail Service Updates
      </a>
    </p>
  </div>
)

export default RmDelayWarning
